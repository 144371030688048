<template lang="pug">
  div
    v-row(justify="space-between" align="center")
      v-col(cols="auto")
        router-link(to="/" style="text-decoration: none;")
          v-icon(left) mdi-arrow-left
          span.d-none.d-sm-inline Return to home
      v-col(cols="auto")
        v-btn(color="secondary" depressed to="/") Start using Sparkl
          v-icon(right) mdi-arrow-right

    v-row.my-12(justify="center")
      v-col.py-0.text-center(cols="auto")
        v-img(
          src="/logo.png",
          :width="$vuetify.breakpoint.xsOnly ? 350 : 500"
          contain
        )

    h1.mt-12.mb-6.primary--text How it Works
    div(style="border-radius: 6px; line-height: 36px;")
      v-row(align="baseline")
        v-col(cols="12" sm="1")
          h1.d-none.d-sm-block 1.
          h2.d-sm-none 1. Join SPARKL 👋
        v-col
          h2.d-none.d-sm-block.mb-3 Join SPARKL 👋
          p You’ve created your account and securely saved your payment details for the deposit system, now you’re ready to start reusing! With SPARKL, check out reusable containers free of charge...unless you keep ‘em!
      v-row(align="baseline")
      v-row(align="baseline")
        v-col(cols="12" sm="1")
          h1.d-none.d-sm-block 2.
          h2.d-sm-none 2. Start Reusing! ♻️
        v-col.pa-0
          h2.d-none.d-sm-block.mb-3 Start Reusing! ♻️+
          v-expansion-panels.mb-6.def-color(flat accordion tile)
            v-expansion-panel(style="background-color: #fafdff")
              v-expansion-panel-header.pa-3
                p.mr-4.mb-0 <span class="font-weight-bold primary--text" style="line-height: 2rem"> Step 1:</span>  Sign up
              v-expansion-panel-content.mr-4 You’ve created your account, now you’re ready to start reusing! With SPARKL, check out up to 4 reusable containers <u>free of charge</u>...unless you keep ‘em! (There’s a $6.50 charge for each unreturned container after 7 days).
            v-expansion-panel(style="background-color: #fafdff")
              v-expansion-panel-header.pa-3
                p.mr-4.mb-0 <span class="font-weight-bold primary--text" style="line-height: 2rem"> Step 2:</span> <router-link to="/return-or-take?type=take">Order a meal</router-link>, ask for reusables
              v-expansion-panel-content.mr-4 Locate one of our participating vendors on our app. Call, walk-in,or order online. Let them know you are a Sparkler & would like your meal in our reusable containers. For Sparkl dispensers, follow directions on the dispenser.
            v-expansion-panel(style="background-color: #fafdff")
              v-expansion-panel-header.pa-3
                p.mr-4.mb-0 <span class="font-weight-bold primary--text" style="line-height: 2rem"> Step 3:</span> Checkout containers
              v-expansion-panel-content.mr-4 Click on the Vendors button and choose your vendor from the list or map pins. Click “Check Out Reusables.” The vendor will confirm how many reusables your order comes in, then you select that number of reusables on the app. Confirm “Take Reusables.” Show staff the confetti confirmation screen, and get your food.
            v-expansion-panel(style="background-color: #fafdff")
              v-expansion-panel-header.pa-3
                p.mr-4.mb-0 <span class="font-weight-bold primary--text" style="line-height: 2rem"> Step 4:</span> Return to a drop station
              v-expansion-panel-content From the home screen, select the Return Stations button to find your nearest return station. With your phone camera, scan the QR code on the front of the station, and confirm in the app the number of containers returned. Once you see the confetti confirmation screen, drop in containers and feel great about choosing zero waste!


      v-row(align="baseline")
        v-col(cols="12" sm="1")
          h1.d-none.d-sm-block 3.
          h2.d-sm-none 3. <router-link to="/return-stations">Return Containers!</router-link> ✨
        v-col
          h2.d-none.d-sm-block.mb-3 <router-link to="/return-stations">Return Containers!</router-link> ✨
          p <span class="font-weight-bold primary--text">Step 1:</span> From the home screen, select the Return Stations button and choose your preferred station.
          p <span class="font-weight-bold primary--text">Step 2:</span> With your phone, scan the QR code on the front of the station, and confirm the number of containers returned.
          p.mb-6 <span class="font-weight-bold primary--text">Step 3:</span> See confirmation of your return; feel great about your waste-free choice!

      v-row(align="baseline")
        v-col(cols="12" sm="1")
          h1.d-none.d-sm-block 4.
          h2.d-sm-none 4. Repeat! 🔄
        v-col
          h2.d-none.d-sm-block.mb-3 Repeat! 🔄
    
    h1.mt-12.mb-6.primary--text FAQ's
    v-expansion-panels
      v-expansion-panel
        v-expansion-panel-header  Questions about Sanitization
        v-expansion-panel-content
          h4.mb-3 Are reusable containers safe to use during the COVID-19 pandemic?
          p.mb-12 All CDC and FDA guidance on safe food service operations supports the use of reusable containers because they are being washed and sanitized to strict Department of Health guidelines. These standards are rigorous and designed to keep users safe from infectious disease. Reusables containers have been shown to be safer for food service than disposable food ware for this reason. To date, there are no known cases of COVID-19 that have spread through sanitized reusable food ware.

          h4.mb-3 What additional steps has SPARKL taken to ensure sanitization?
          p At every step of our operations, we utilize CDC recommended products and guidelines for sanitization.
          p.mb-12 Our containers will always be washed at Department of Health certified facilities utilizing commercial-grade dishwashing equipment, then securely stored until we distribute them to the restaurants, preventing contamination.

          h4.mb-3 Is eating off of reusable takeout containers as safe as eating with dine-in reusables?
          p.mb-12 If you’re comfortable using dishes when you dine-in at restaurants, you can rest assured we use the same practices for our takeout wares! Reusable containers are safer and better for the environment!
      v-expansion-panel
        v-expansion-panel-header  Using SPARKL
        v-expansion-panel-content
          h4.mb-3 How much time do I have to return my container(s)?
          p.mb-12 We ask that all users return their container(s) within 7 days of checking them out. If containers are not returned, we will, unfortunately, have to charge you the value of each container ($6.50). Don’t worry, we’ll remind you before you’re charged.
          
          h4.mb-3 How do I return my checked out containers?
          p Using the app, select the Return Stations button and locate your nearest station on the map. You found it! Then, using your smartphone camera, scan the QR code on the front of the return station, and select how many containers you are checking back in and depositing to the station. The Confetti Confirmation Screen means your return was successfully logged, and that you’re all set for your next meal in reusables!

          h4.mb-3 How much can I use the SPARKL service?
          p.mb-12 You can use the SPARKL service an unlimited number of times per year as long as you continue returning the containers.

          h4.mb-3 What if I lose my container or it breaks?
          p.mb-12 Contact SPARKL at info@sparklreusables.com to let us know what happened and we will work it out together.

          h4.mb-3 Do I need to wash my container before placing it in a return station? How can I best return it?
          p.mb-12 Please return your container as free of food as possible. Rinsing, drying and placing them open and face up in the return station is most helpful!

          h4.mb-3 Can my SPARKL container go in the microwave?
          p Sparkl containers are BPA and BPS-free, freezer, dishwasher and microwave safe for reheating. The containers are also NSF and UL certified. Please only microwave in a low temp setting for no more than 1-3 minutes. Transfer food to a different container if it requires cutting with a sharp knife. 

          h4.mb-3 How do I Cancel My Account
          p.mb-12 Please send an email to info@sparklreusables.com titled “Please Cancel My Account.” If possible, please include the reason as we value honest feedback!
      v-expansion-panel
        v-expansion-panel-header  Technology Questions
        v-expansion-panel-content
          h4.mb-3 How do I resolve an App problem?
          p.mb-12 Usually logging out and back in resolves many App issues. You can log out by tapping the three dots at the top right corner of the homescreen and choosing “Log Out”. If that did not solve the problem, please contact us at info@sparklreusables.com

          h4.mb-3 How can I bookmark the SPARKL App onto my phone’s home screen?
          p If you have an iPhone, you can bookmark the SPARKL app by clicking on the “Share” icon (icon with Up Arrow) then scroll down and click “Add to Home Screen.” On Android phones, click on the three dots at the top right of your screen and choose “Add to Homescreen.” Some phone models will automatically prompt you to add the webapp bookmark to your home screen.
          p.mb-12 When in doubt, we suggest Google it!

          h4.mb-3 Why doesn’t my camera automatically open to scan the QR code?
          p Make sure to manually open your phone’s camera, point it to the QR code, hold steady for a second, and click the link that appears. Your return will be recorded after you select the number of containers being returned, and will appear in “My SPARKL History.”
          p.mb-12 On some models, you will need to opt-in to authorize your camera to automatically open if accessing the QR scanner through the app instead of by opening your camera manually to scan.
      v-expansion-panel
        v-expansion-panel-header  Environmental and Social Impact Questions
        v-expansion-panel-content
          h4.mb-3 Am I making a positive impact by joining SPARKL? What are some of the positive benefits of a reusable takeout container system?
          p Food related debris make up 65% of trash found on SF Bay Area streets and county landfills are overflowing with single-use items. Offering a reusable takeout container system helps to address this issue (and many others) while also cultivating circular economies.
          p There are numerous social, economic, and environmental benefits of supporting a reusable container program like SPARKL. Product stewardship, increasing local economic opportunities and community building are at the heart of SPARKL’s mission.
          p We’ve worked closely with community partners to design a waste-free takeout model that fits local needs. We are excited to offer a program that will reduce costs for local restaurants by creating less waste for our cities and minimizing the amount of single-use items our restaurants have to purchase.
          p According to the Clean Water Action Network, “reusable food service containers have lower overall greenhouse emissions or energy usage than comparable single- use products.” *
          p Across the “upstream” and “downstream” waypoints of our wastestream, enormous quantities of Greenhouse Gas (GHG) emissions are generated during extraction, processing, transportation, and disposal. These emissions directly contribute to the climate crisis we are facing, with nearly 40% of GHG emissions coming from the way we make, take, ship and dispose of goods! **
          p By reusing containers hundreds of times we can avoid the GHG emissions generated from constantly manufacturing, shipping, and tossing single-use items.
          small * Citation source: Clean Water Action Network report: Greenhouse Gas Impacts of Disposable vs Reusable Foodservice Products, January 2017
          small.mb-12 ** Citation source: EPA.gov, Global Greenhouse Gas Emissions Data page
      v-expansion-panel
        v-expansion-panel-header  Container Questions
        v-expansion-panel-content
          h4.mb-3 How many times can each container be reused and what happens to the containers at the end of life?
          p Each container can be reused 300+ times. When the containers can no longer be used they are bundled with other #5 plastics and turned back into more containers, plates, bowls and/or other durable goods.
          
          h4.mb-3 What material are SPARKL containers made of?
          p The containers we use are always BPA and BPS-free and made with #5 Polypropylene which is one of the safest plastics with excellent heat and acidity-resistance qualities.
          p.mb-12 Every material type (reusable plastic, stainless steel, etc.) has social and environmental impacts to consider. Whether it’s mining for metals or extracting fossil fuel. The good news is that reusables have way less of an impact than single-use disposable products. We primarily use #5 BPA and BPS-free polypropylene containers for their versatility and ease of handling and we care for them from cradle to grave.


    h1.mt-12.mb-6.primary--text(id="contact") Contact Us
    p.mb-12 Please get in touch with us by sending an email to <a href="mailto:info@sparklreusables.com">info@sparklreusables.com</a>.
</template>

<script>
export default {
  mounted() {
    if (this.$route.query.to) {
      this.$vuetify.goTo(`#${this.$route.query.to}`);
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  margin-bottom: 12px !important;
}

</style>
